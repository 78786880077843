.pageContainer {
  display: grid;
  gap: var(--spacing-2);
  color: var(--color-mine-shaft);
  justify-content: center;
  grid-template-columns: minmax(0, 1fr);
}

.statusHeroWrapper {
  display: grid;
  gap: var(--spacing-2);
}
.statusHeroWrapper:empty {
  display: none;
}

.infoBlock {
  display: block;
  width: 100%;
}

.bottomButtonSection {
  margin-bottom: var(--spacing-5);
}
.bottomButtonSection > button {
  width: 100%;
}

@media (min-width: 768px) {
  .pageContainer {
    grid-template-columns: 375px minmax(0, 1fr);
    gap: var(--spacing-3);
  }

  .statusHeroWrapper {
    grid-column: span 2;
    gap: var(--spacing-3);
  }
}
