/* Colors */

.colorBlue {
  color: var(--color-blue);
}

.colorBlueActive {
  color: var(--color-blue-active);
}

.colorBlueHover {
  color: var(--color-blue-hover);
}

.colorBrown {
  color: var(--color-brown);
}

.colorDarkGray1 {
  color: var(--color-dark-gray1);
}

.colorDarkGray1Active {
  color: var(--color-dark-gray1-active);
}

.colorDarkGray1Hover {
  color: var(--color-dark-gray1-hover);
}

.colorDarkGray2 {
  color: var(--color-dark-gray2);
}

.colorForest {
  color: var(--color-forest);
}

.colorGray1 {
  color: var(--color-gray1);
}

.colorGray2 {
  color: var(--color-gray2);
}

.colorGray3 {
  color: var(--color-gray3);
}

.colorHallon1 {
  color: var(--color-hallon1);
}

.colorHallon1Active {
  color: var(--color-hallon1-active);
}

.colorHallon1Hover {
  color: var(--color-hallon1-hover);
}

.colorHallon2 {
  color: var(--color-hallon2);
}

.colorHallon3 {
  color: var(--color-hallon3);
}

.colorHallon4 {
  color: var(--color-hallon4);
}

.colorHallon4Active {
  color: var(--color-hallon4-active);
}

.colorHallon4Hover {
  color: var(--color-hallon4-hover);
}

.colorHallon5 {
  color: var(--color-hallon5);
}

.colorHallon6 {
  color: var(--color-hallon6);
}

.colorHallon6Hover {
  color: var(--color-hallon6-hover);
}

.colorHallon6Active {
  color: var(--color-hallon6-active);
}

.colorLemon1 {
  color: var(--color-lemon1);
}

.colorLemon2 {
  color: var(--color-lemon2);
}

.colorLemon3 {
  color: var(--color-lemon3);
}

.colorMineShaft {
  color: var(--color-mine-shaft);
}

.colorMineShaftActive {
  color: var(--color-mine-shaft-active);
}

.colorMineShaftHover {
  color: var(--color-mine-shaft-hover);
}

.colorNyanza {
  color: var(--color-nyanza);
}

.colorNyanzaHover {
  color: var(--color-nyanza-hover);
}

.colorNyanzaActive {
  color: var(--color-nyanza-active);
}

.colorOcean {
  color: var(--color-ocean);
}

.colorRose {
  color: var(--color-rose);
}

.colorSand {
  color: var(--color-sand);
}

.colorSky {
  color: var(--color-sky);
}

.colorWhite {
  color: var(--color-white);
}

.colorWhiteActive {
  color: var(--color-white-active);
}

.colorWhiteHover {
  color: var(--color-white-hover);
}

.colorTransparent {
  color: var(--color-transparent);
}

/* Background colors */

.bgColorBlue {
  background-color: var(--color-blue);
}

.bgColorBlueActive {
  background-color: var(--color-blue-active);
}

.bgColorBlueHover {
  background-color: var(--color-blue-hover);
}

.bgColorBrown {
  background-color: var(--color-brown);
}

.bgColorDarkGray1 {
  background-color: var(--color-dark-gray1);
}

.bgColorDarkGray1Active {
  background-color: var(--color-dark-gray1-active);
}

.bgColorDarkGray1Hover {
  background-color: var(--color-dark-gray1-hover);
}

.bgColorDarkGray2 {
  background-color: var(--color-dark-gray2);
}

.bgColorForest {
  background-color: var(--color-forest);
}

.bgColorGray1 {
  background-color: var(--color-gray1);
}

.bgColorGray2 {
  background-color: var(--color-gray2);
}

.bgColorGray3 {
  background-color: var(--color-gray3);
}

.bgColorHallon1 {
  background-color: var(--color-hallon1);
}

.bgColorHallon1Active {
  background-color: var(--color-hallon1-active);
}

.bgColorHallon1Hover {
  background-color: var(--color-hallon1-hover);
}

.bgColorHallon2 {
  background-color: var(--color-hallon2);
}

.bgColorHallon3 {
  background-color: var(--color-hallon3);
}

.bgColorHallon4 {
  background-color: var(--color-hallon4);
}

.bgColorHallon4Active {
  background-color: var(--color-hallon4-active);
}

.bgColorHallon4Hover {
  background-color: var(--color-hallon4-hover);
}

.bgColorHallon5 {
  background-color: var(--color-hallon5);
}

.bgColorHallon6 {
  background-color: var(--color-hallon6);
}

.bgColorHallon6Hover {
  background-color: var(--color-hallon6-hover);
}

.bgColorHallon6Active {
  background-color: var(--color-hallon6-active);
}

.bgColorLemon1 {
  background-color: var(--color-lemon1);
}

.bgColorLemon2 {
  background-color: var(--color-lemon2);
}

.bgColorLemon3 {
  background-color: var(--color-lemon3);
}

.bgColorMineShaft {
  background-color: var(--color-mine-shaft);
}

.bgColorMineShaftActive {
  background-color: var(--color-mine-shaft-active);
}

.bgColorMineShaftHover {
  background-color: var(--color-mine-shaft-hover);
}

.bgColorNyanza {
  background-color: var(--color-nyanza);
}

.bgColorNyanzaHover {
  background-color: var(--color-nyanza-hover);
}

.bgColorNyanzaActive {
  background-color: var(--color-nyanza-active);
}

.bgColorOcean {
  background-color: var(--color-ocean);
}

.bgColorRose {
  background-color: var(--color-rose);
}

.bgColorSand {
  background-color: var(--color-sand);
}

.bgColorSky {
  background-color: var(--color-sky);
}

.bgColorWhite {
  background-color: var(--color-white);
}

.bgColorWhiteActive {
  background-color: var(--color-white-active);
}

.bgColorWhiteHover {
  background-color: var(--color-white-hover);
}

.bgColorTransparent {
  background-color: var(--color-transparent);
}
